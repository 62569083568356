'use client';

import Script from 'next/script';
import { useCallback } from 'react';

export function AnalyticsPendoInternal({
  apiKey,
  accountId,
  userEmail,
  userFirstName,
  userId,
  userLastName,
}: {
  apiKey: string;
  accountId: string;
  userId: string;
  userEmail: string;
  userFirstName: string | null;
  userLastName: string | null;
}) {
  const onReady = useCallback(() => {
    const pendo = (window as any).pendo;
    if (!pendo) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: userId,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
      },
      account: {
        id: accountId,
      },
    });
  }, [accountId, userEmail, userFirstName, userId, userLastName]);

  return <Script src={`https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`} onReady={onReady} />;
}
